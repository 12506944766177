'use client'

import React, { memo } from 'react'
import Link from 'next/link'
import { useCLickEvent } from '@/hooks/use-tracker'

export const Footer = memo(() => {
  const { pushGa4ClickEvent } = useCLickEvent()
  return (
    <footer className={`bg-darken maxsm:mb-[58px]`}>
      <div className="sm:px-8 sm:py-16 maxlg:px-4 px-6 py-10">
        <h3 className="">
          <img src="/images/img_logo02.svg" alt="LAWGUE" />
        </h3>
        <div className="flex flex-wrap items-baseline mt-10">
          <div className="sm:flex flex-wrap items-baseline maxsm:w-6/12">
            <ul>
              <li>
                <div>
                  <span className="text-white text-sm font-bold">
                    ソリューション
                  </span>
                  <div className="flex flex-col items-baseline">
                    <Link href="/contract" legacyBehavior>
                      <a className="text-white text-xs mt-2 sm:transition sm:hover:opacity-80">
                        契約ナレッジマネジメント
                      </a>
                    </Link>
                    <Link href="/regulation" legacyBehavior>
                      <a className="text-white text-xs mt-2 sm:transition sm:hover:opacity-80">
                        規程・マニュアル管理
                      </a>
                    </Link>
                    <Link href="/law" legacyBehavior>
                      <a className="text-white text-xs mt-2 sm:transition sm:hover:opacity-80">
                        法令改正対応
                      </a>
                    </Link>
                    <Link href="/translation" legacyBehavior>
                      <a className="text-white text-xs mt-2 sm:transition sm:hover:opacity-80">
                        英文契約書レビュー
                      </a>
                    </Link>
                    <a
                      href="https://d1.lawgue.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-white text-xs mt-2 sm:transition sm:hover:opacity-80 flex"
                    >
                      省庁・自治体向け
                      <img
                        src="/images/img_icon_externallink-white.svg"
                        alt=""
                        className="ml-2"
                      />
                    </a>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="sm:ml-14 maxsm:mt-5">
              <li>
                <div>
                  <span className="text-white text-sm font-bold">製品紹介</span>
                  <div className="flex flex-col items-baseline">
                    <Link href="/functions" legacyBehavior>
                      <a className="text-white text-xs mt-2 sm:transition sm:hover:opacity-80">
                        機能
                      </a>
                    </Link>
                    <Link href="/security" legacyBehavior>
                      <a className="text-white text-xs mt-2 sm:transition sm:hover:opacity-80">
                        セキュリティ
                      </a>
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="sm:ml-14 maxsm:mt-5">
              <li>
                <Link href="/flow" legacyBehavior>
                  <a className="text-white text-sm font-bold sm:transition sm:hover:opacity-80">
                    料金・導入の流れ
                  </a>
                </Link>
              </li>
            </ul>
          </div>
          <div className="sm:flex flex-wrap items-baseline maxsm:w-6/12">
            <ul className="sm:ml-14 maxsm:mt-5">
              <li>
                <div>
                  <Link href="/voice" legacyBehavior>
                    <a className="text-white text-sm font-bold sm:transition sm:hover:opacity-80">
                      導入事例
                    </a>
                  </Link>
                </div>
              </li>
            </ul>
            <ul className="sm:ml-14 maxsm:mt-5">
              <li>
                <div>
                  <span className="text-white text-sm font-bold">
                    お役立ち情報
                  </span>
                  <div className="flex flex-col items-baseline">
                    <Link href="/service" legacyBehavior>
                      <a className="text-white text-xs mt-2 sm:transition sm:hover:opacity-80">
                        お役立ち資料
                      </a>
                    </Link>
                    <Link href="/seminar" legacyBehavior>
                      <a className="text-white text-xs mt-2 sm:transition sm:hover:opacity-80">
                        セミナー
                      </a>
                    </Link>
                    <Link href="/column" legacyBehavior>
                      <a className="text-white text-xs mt-2 sm:transition sm:hover:opacity-80">
                        コラム
                      </a>
                    </Link>
                    <Link href="/news" legacyBehavior>
                      <a className="text-white text-xs mt-2 sm:transition sm:hover:opacity-80">
                        お知らせ
                      </a>
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="sm:ml-14 maxsm:mt-5">
              <li>
                <div>
                  <Link href="/partnership" legacyBehavior>
                    <a className="text-white text-sm font-bold sm:transition sm:hover:opacity-80">
                      パートナーシップ
                    </a>
                  </Link>
                </div>
              </li>
            </ul>
            <ul className="sm:ml-14 maxsm:mt-5">
              <li className="mt-2">
                <Link
                  href="/contact"
                  legacyBehavior
                  onClick={() => {
                    // TODO separate
                    pushGa4ClickEvent({
                      eventCategory: 'contact',
                      eventUi: 'footer',
                    })
                  }}
                >
                  <a className="text-white text-sm font-bold sm:transition sm:hover:opacity-80">
                    お問い合わせ
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="items-center sm:justify-between flex sm:px-8 sm:py-10 maxlg:px-4 px-6 py-10 border-t border-solid border-white maxsm:flex-col maxsm:items-start">
        <div className="maxsm:order-2 maxsm:mt-10">
          <p className="text-white text-xs">© 2023 FRAIM株式会社</p>
        </div>
        <div className="maxsm:order-1">
          <ul className="items-center sm:flex">
            <li>
              <a
                href="https://fraim.co.jp/"
                target="_blank"
                rel="noreferrer"
                className="text-white text-xs sm:ml-6 sm:transition sm:hover:opacity-80"
              >
                運営会社
              </a>
            </li>
            <li className="maxsm:mt-2">
              <Link href="/terms" legacyBehavior>
                <a className="text-white text-xs sm:ml-6 sm:transition sm:hover:opacity-80">
                  利用規約
                </a>
              </Link>
            </li>
            <li className="maxsm:mt-2">
              <Link href="/privacy-policy" legacyBehavior>
                <a className="text-white text-xs sm:ml-6 sm:transition sm:hover:opacity-80">
                  プライバシーポリシー
                </a>
              </Link>
            </li>
            <li className="maxsm:mt-2">
              <a
                href="https://fraim.co.jp/security/"
                target="_blank"
                rel="noreferrer"
                className="text-white text-xs sm:ml-6 sm:transition sm:hover:opacity-80"
              >
                情報セキュリティ方針
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
})

Footer.displayName = 'Footer'
